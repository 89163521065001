:root {
    --PhoneInput-color--focus: #66afe9;
}

.PhoneInput--focus{
    border-color: var(--PhoneInput-color--focus);
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

input.PhoneInputInput{
    border: 0px;
    outline: 0px;
}

.custom-modal-background {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    z-index: 100;
}

.custom-modal {
    position: relative;
    width: 500px;
    height: max-content;
    padding-bottom: 10px;
    left: 50%;
    margin-left: -250px;
    top: 50%;
    margin-top: -250px;
    background: #FFF;
}

.custom-modal .close {
    padding: 5px 10px;
}

.custom-modal-body {
    padding: 30px 10px 0 10px;
}

@media (max-width: 500px) {
    .custom-modal {
        width: 90%;
        margin-left: 5%;
        left: auto;
    }
}

.custom-modal-footer {
    display: flex;
    justify-content: right;
    padding-right: 4px;
}

.custom-modal-button {
    margin: 0px 6px;
}
