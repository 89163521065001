.header {
    background-color: #0060af;
    height: 60px; 
}

.logo {
    max-height: 40px;
    margin: 10px 0;
}

.user-image {
    max-height: 40px;
    margin: 10px 0;
    margin-right: 10px;
    float: right;
}

.user-label {
    float: right;
    color: #fff;
    line-height: 60px;
}

@media (max-width: 576px) 
{
    .logo {
        max-height: 30px;
        margin: 15px 0;
    }
        
    .user-image {
        max-height: 30px;
        margin: 15px 0;
        margin-right: 10px;
    }

    .user-label {
        display: none;
    }

}