.custom-modal-background {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    z-index: 100;
}

.custom-modal {
    position: relative;
    width: 500px;
    height: 500px;
    left: 50%;
    margin-left: -250px;
    top: 50%;
    margin-top: -250px;
    background: #FFF;
}

    .custom-modal .close {
        padding: 5px 10px;
    }

.custom-modal-body {
    padding: 30px 10px 0 10px;
}

@media (max-width: 500px) {
    .custom-modal {
        width: 90%;
        margin-left: 5%;
        left: auto;
    }
}
