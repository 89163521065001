body {
  background-color: #eceff4;
  font-size: 0.92rem;
}

strong {
  font-weight: 600;
}

.sc-content-spinner {
  display: block;
  margin: auto;
  margin-top: 25%;
  width: 25%;
}

.card-header h5 {
  margin: 0;
}

.primary-card-header {
  background-color: #004a87;
}

.secondary-card-header {
  background-color: #666;
}

.form-control {
  background: none;
  background-image: none;
  border: 1px solid rgba(0, 0, 0, 0.26);
  border-radius: 3px;
  padding-left: 3px;
}

.small {
  color: #777;
}

.form-control:invalid {
  background-image: none;
}

.pro-description-secondary {
  color: #777;
}

.bmd-form-group.is-focused .form-control {
  background: none;
  border-color: #0060af;
}

.custom-control-label {
  font-size: 0.9em;
}

.custom-radio .custom-control-label::before {
  top: 0;
}

.custom-radio .custom-control-label::after {
  top: 0;
}

.custom-checkbox .custom-control-label::before {
  top: 0;
}

.custom-checkbox .custom-control-label::after {
  top: 0;
}

.steps {
  display: inline-flex;
  padding-bottom: 1rem;
}

.step {
  height: 20px;
  width: 20px;
  color: #fff;
  font-size: 12px;
  border-radius: 10px;
  text-align: center;
}

.step.active-step {
  background-color: #1058ab;
}

.step.inactive-step {
  background-color: #6d6d6d;
}

.steps > span {
  font-size: 14px;
  margin-left: 4px;
  margin-right: 14px;
}

.header {
  background-color: #0060af;
  height: 60px;
}

.secondary-header {
  background-color: #004a87;
  height: 42px;
}

.gray-header {
  background-color: #666;
  height: 42px;
}

.btn {
  border-radius: 2px;
}

.card {
  border-radius: 0;
}

.card-header {
  border-radius: 0;
}

.list-group-item {
  border-radius: 0;
}

.text-primary {
  color: #f26700 !important;
}

.btn-primary {
  background: #f26700;
  border: 1px solid #f26700;
}

.btn-primary:hover {
  background: #f26700b8;
  border: 1px solid #f26700b8;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background: #f26700b8;
  border: 1px solid #f26700b8;
}

.btn-light {
  border: 1px solid #aaa;
}

.gloo-btn-link:hover {
  text-decoration: none;
}

.gloo-btn {
  background-color: white;
  border: 2px solid #41B768;
  border-radius: 16px;
  color: #41B768;
  font-family: poppins;
  font-size: 1.1rem;
  min-height: 50px;
  padding: 2px 0px;
  outline: none;
  text-transform: none;
}

.gloo-btn:hover {
  color: #41B768;
  background-color: rgb(0, 0, 0, 0.05);
}

.gloo-icon {
  transform: scale(3);
  margin: 0px 24px 1px 28px;
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.strikethrough {
  position: relative;
}

.strikethrough:before {
  position: absolute;
  content: "";
  left: -3px;
  top: 50%;
  right: -3px;
  border-top: 1px solid;
  border-color: inherit;
  -webkit-transform: rotate(-12deg);
  -moz-transform: rotate(-12deg);
  -ms-transform: rotate(-12deg);
  -o-transform: rotate(-12deg);
  transform: rotate(-12deg);
}

.custom-snackbar {
  position: fixed;
  bottom: 1%;
  right: 1%;
}

.input-group-text {
  font-size: 30px;
  padding: 5px;
}

.fa-expeditedssl {
  font-size: 50px;
}

.buttonLink {
  color: inherit !important;
  text-decoration: none !important;
}

.caret {
  left: 218px;
  top: -29px;
  width: 20px;
  position: absolute;
}

.caret div:first-of-type {
  border-color: #fff transparent;
  top: 1px;
  border: 14px solid;
  position: absolute;
  z-index: 1;
  border-bottom: #fff;
  border-bottom-style: solid;
  border-bottom-width: 14px;
  border-left-color: rgb(0, 0, 0, 0);
  border-left-style: solid;
  border-left-width: 14px;
  border-right-color: rgb(0, 0, 0, 0);
  border-right-style: solid;
  border-right-width: 14px;
  border-top-color: rgb(0, 0, 0, 0);
  border-top-style: solid;
  border-top-width: 14px;
}

.caret div:last-of-type {
  border-top-width: 0;
  border-color: rgba(0, 0, 0, 0.2) transparent;
  border: 14px solid;
  position: absolute;
  border-bottom: #007bff;
  border-bottom-style: solid;
  border-bottom-width: 14px;
  border-left-color: rgb(0, 0, 0, 0);
  border-left-style: solid;
  border-left-width: 14px;
  border-right-color: rgb(0, 0, 0, 0);
  border-right-style: solid;
  border-right-width: 14px;
  border-top-color: rgb(0, 0, 0, 0);
  border-top-style: solid;
  border-top-width: 14px;
}

.cancel-anchor {
  margin: 0;
  position: "absolute";
  top: "50%";
  transform: "translateY(-50%)";
}

#main-banner-container {
  position: relative;
}

#main-banner-container > img {
  width: 255px;
  position: absolute;
  bottom: -35px;
  left: 100px;
}

.gray-scale-font {
  color: #353d41;
}

.gray-scale-line {
  border-top: 2px solid #dddddd;
}

.gray-scale-opacity {
  opacity: 0.8;
}

.font-weight-500 {
  font-weight: 500;
}

.blue-scale-font {
  color: #178fea;
}

.bluer-scale-font {
  color: rgb(23, 142, 234);
}

.input-soft-border {
  border: 2px solid #dddddd;
  border-radius: 4px;
}

.cc-input-part:-webkit-autofill {
  -webkit-text-fill-color: #000;
  box-shadow: 0 0 0px 1000px #fff inset;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

.password-input,
.faux-cc-input,
.faux-zip-cvc-input {
  height: 48px;
}

.faux-cc-input input,
.faux-zip-cvc-input input {
  height: 100%;
  width: 100%;
  border: none;
}

.password-input input::placeholder,
.faux-cc-input input::placeholder,
.faux-zip-cvc-input input::placeholder {
  font-weight: 500;
  font-size: 16px;
  opacity: 0.8;
}

.faux-cc-input #expiry-container {
  height: 100%;
}

.faux-cc-input #expiry-container > input:nth-child(1) {
  padding-right: 0px;
  width: 40px;
  text-align: center;
  border-radius: 0px;
}

.faux-cc-input span {
  height: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: 3px;
}

.faux-cc-input #expiry-container > input:nth-child(2) {
  padding-right: 0px;
  width: 60px;
  text-align: left;
  border-radius: 0px;
}

.faux-cc-input #cvc-input {
  text-align: end;
}

.zip-code input::placeholder {
  font-weight: 500;
  font-size: 16px;
  opacity: 0.8;
}

.full-account .checkbox-card {
  width: 24px;
  height: 24px;
  clear: both;
  display: block;
  padding: 0px 0px;
  border-radius: 4px;
  border: 2px solid #dddddd;
}

.full-account .checkbox-card::after {
  clear: both;
  display: block;
  content: "";
}

.full-account .checkbox-card .checkbox-container {
  float: left;
  width: 50%;
  box-sizing: border-box;
  text-align: center;
}

.full-account .checkbox-card .circular-container {
  background-color: #0067ff;
}

.full-account .checkbox-card .input-title {
  clear: both;
  padding: 22px 0px 0px 0px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 300;
}

.full-account .checkbox-label {
  display: block;
  margin: auto;
  cursor: pointer;
  font-size: 20px;
  line-height: 19px;
  height: 19px;
  width: 19px;
  clear: both;
}

.full-account .checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.full-account .checkbox-label .checkbox-custom {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 19px;
  width: 19px;
  background-color: transparent;
  border-radius: 5px;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  border: 2px solid #ffffff;
}

.full-account .checkbox-label input:checked ~ .checkbox-custom {
  background-color: #ffffff;
  border-radius: 5px;
  -webkit-transform: rotate(0deg) scale(1);
  -ms-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  opacity: 1;
  border: 2px solid #ffffff;
}

.full-account .checkbox-label .checkbox-custom::after {
  position: absolute;
  content: "";
  left: 12px;
  top: 12px;
  height: 0px;
  width: 0px;
  border-radius: 5px;
  border: solid #009bff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(0deg) scale(0);
  -ms-transform: rotate(0deg) scale(0);
  transform: rotate(0deg) scale(0);
  opacity: 1;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}

.full-account .checkbox-label input:checked ~ .checkbox-custom::after {
  -webkit-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  opacity: 1;
  left: 6px;
  top: 2px;
  width: 6px;
  height: 10px;
  border: solid #009bff;
  border-width: 0 2px 2px 0;
  background-color: transparent;
  border-radius: 0;
}

.full-account .checkbox-label .checkbox-custom::before {
  position: absolute;
  content: "";
  left: 10px;
  top: 10px;
  width: 0px;
  height: 0px;
  border-radius: 5px;
  border: 2px solid #ffffff;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

.full-account .checkbox-label input:checked ~ .checkbox-custom::before {
  left: -3px;
  top: -3px;
  width: 24px;
  height: 24px;
  border-radius: 5px;
  -webkit-transform: scale(3);
  -ms-transform: scale(3);
  transform: scale(3);
  opacity: 0;
  z-index: 999;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}

.blue-custom-radio {
  display: block;
  position: relative;
  padding-left: 27px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 4px;
}

.blue-custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.blue-custom-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: 1px solid #2196f3;
  background-color: #fff;
}

.blue-custom-radio:hover input ~ .checkmark {
  background-color: #4dabf5;
}

.blue-custom-radio input:checked ~ .checkmark {
  background-color: #fff;
}

.blue-custom-radio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.blue-custom-radio input:checked ~ .checkmark:after {
  display: block;
}

.blue-custom-radio .checkmark:after {
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #2196f3;
}

.promo-input {
  background-color: rgb(238, 240, 242);
  border: none;
}

.blue-primary-button {
  background-color: rgb(23, 142, 234);
  color: #fff;
}

.blue-primary-button:hover {
  background-color: rgb(116, 191, 248);
  color: #fff;
}

.selected-plan-box {
  border: 2px solid rgb(23, 142, 234);
  border-radius: 4px;
  height: 100px;
  width: 100%;
  position: relative;
}

.outline-selected-plan-box:after {
  content: "\2713";
  position: absolute;
  right: -8px;
  top: -8px;
  font-size: 10px;
  font-weight: 700;
  background: #2196f3;
  color: #fff;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  box-shadow: 0 0 1px #333;
  margin-top: -3px;
}

.gray-scale {
  border-color: #d6d6d6;
}

.gray-scale:after {
  background: #d6d6d6;
}

.selected-plan-box .selected-plan-info {
  margin: 0 auto;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
}

.selected-plan-box .bill-cycle {
  opacity: 0.5;
  font-weight: 500;
}

.bill-cycle-intl {
  opacity: 0.5;
  font-weight: 500;
  font-size: 13px;
}

.selected-plan-box .price-structure {
  color: rgb(23, 142, 234);
  font-size: 24px;
  font-weight: 500;
}

.change-plan-label {
  color: rgb(23, 142, 234);
  font-style: italic;
  font-weight: 500;
  cursor: pointer;
}

#due-today-mobile {
  display: none;
}

.custom-cc-feedback {
  margin-top: -1rem;
  margin-bottom: 4px;
}

.custom-cc-feedback > div {
  display: block;
}

.select-soft {
  border: none;
  font-weight: 500;
  font-size: 17px;
  padding-left: 13px;
}

.need-more-feedback {
  color: #dc3545;
  font-size: 80%;
  margin-top: 0.25rem;
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .cancel-button-row {
    display: none;
  }

  .cancel-select {
    display: block;
  }

  .caret {
    display: none;
  }

  #main-banner-container {
    display: none;
  }

  #mobile-banner-container {
    display: block;
  }

  .inital-cancel-card {
    padding-top: 10px;
  }
}

@media only screen and (min-width: 1201px) {
  .cancel-select {
    display: none;
  }

  #mobile-banner-container {
    display: none;
  }

  .inital-cancel-card {
    padding-top: 45px;
  }
}

@media only screen and (max-width: 768px) {
  .cancel-anchor {
    transform: initial;
  }

  .non-mobile-help {
    display: none;
  }

  .mobile-help {
    display: block;
  }

  .non-mobile-cancel-ask {
    display: none;
  }
}

@media only screen and (min-width: 769px) {
  .mobile-help {
    display: none;
  }
}

@media only screen and (max-width: 400px) {
  .cancel-yes {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

@media only screen and (min-width: 996px) {
  .cancel-icon-container {
    display: flex;
  }

  .new-annual-fee {
    padding-right: 8px;
  }

  .one-time-offer {
    padding-left: 8px;
  }

  .continue-canceling {
    padding-right: 8px;
  }

  .stop-canceling {
    padding-left: 8px;
  }
}

@media only screen and (max-width: 996px) {
  .cancel-icon-container {
    display: none;
  }

  .new-annual-fee {
    padding-right: 16px;
  }

  .one-time-offer {
    padding-left: 16px;
    margin-top: 16px;
  }

  #price-structure {
    margin-left: auto;
    margin-right: auto;
    padding: 8px;
  }

  #price-secondary-structure {
    margin-left: auto;
    margin-right: auto;
    padding: 8px;
  }

  .continue-canceling {
    padding-right: 0px;
    padding-left: 0px;
  }

  .stop-canceling {
    padding-right: 0px;
    padding-left: 0px;
    margin-top: 8px;
  }
}

/* Bootstrap Grid medium and smaller (med maxxes out at 991) */
@media only screen and (max-width: 991px) {
  .testimonials {
    display: none;
  }

  .cc-image {
    display: none;
  }
}

/* Bootstrap Grid small and smaller (small maxxes out at 575) */
@media only screen and (max-width: 575px) {
  #due-today-mobile {
    display: block;
  }

  #due-today-mobile > div > div:nth-child(1) {
    white-space: nowrap;
    text-align: end;
  }

  #due-today-mobile > div > div > label {
    margin-bottom: 0px;
  }

  #due-today-mobile > div > div > label:last-of-type {
    margin-top: -9px;
  }

  #due-today-non-mobile {
    display: none !important;
  }

  #submit-button-full-account {
    padding: 4px 8px;
    font-size: 14px;
  }

  #submit-button-full-account {
    line-height: 2.5rem;
  }
}

.spinner-border {
  height: 15px;
  width: 15px;
}
