.custom-modal-background {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    z-index: 100;
}

.custom-modal {
    position: relative;
    width: 500px;
    height: 400px;
    left: 50%;
    margin-left: -250px;
    top: 50%;
    margin-top: -200px;
    background: #FFF;
}

    .custom-modal .close {
        padding: 5px 10px;
    }

.custom-modal-body {
    padding: 30px 10px 0 10px;
}

@media (max-width: 500px) {
    .custom-modal {
        width: 90%;
        margin-left: 5%;
        left: auto;
    }
}

.strikethrough {
    position: relative;
  }
  .strikethrough:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid;
    border-color: inherit;
  
    -webkit-transform:rotate(-5deg);
    -moz-transform:rotate(-5deg);
    -ms-transform:rotate(-5deg);
    -o-transform:rotate(-5deg);
    transform:rotate(-5deg);
  }

  .church-media-set-items {
      max-height: 400px;
      overflow-y: auto;
      overflow-x: hidden;
  }